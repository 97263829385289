import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getToken = () => {
    const Token = localStorage.getItem('AccessToken');
    return !!Token ? Token : '';
};

const getAllUsers = async (searchText, SelectedFilter, CurrentPage) => {
    let queryParams = searchText ? `?search=${searchText}&plan=${SelectedFilter !== 'All' ? SelectedFilter : ''}&page=${CurrentPage}&pageSize=10` : SelectedFilter && SelectedFilter !== 'All' ? `?plan=${SelectedFilter}&page=${CurrentPage}&pageSize=10` : `?page=${CurrentPage}&pageSize=10`;

    return axios.get(`${BASE_URL}/DetailsPagination${queryParams}`, {
        method: 'GET',
        headers: {
            'ngrok-skip-browser-warning': 'true',
            'Authorization': JSON.parse(getToken())
        }
    });
};

const getTotalUser = async () => {
    return axios.get(`${BASE_URL}/TotalUser`, {
        method: 'GET',
        headers: {
            'ngrok-skip-browser-warning': 'true',
            'Authorization': JSON.parse(getToken())
        }
    });
};

const changeUserStatus = async (payload) => {
    return axios.post(`${BASE_URL}/addStatus`, payload, {
        method: 'POST',
        headers: {
            'ngrok-skip-browser-warning': 'true',
            'Authorization': JSON.parse(getToken())
        }
    });
};

export { getAllUsers, getTotalUser, changeUserStatus }