import React from 'react'
import Logo from './../assets/images/Logo.svg'
import Logout from './../assets/images/logout.svg'
import { useNavigate } from "react-router-dom"
import { Tooltip as ReactTooltip } from "react-tooltip";

export const Header = () => {
    const navigate = useNavigate();
    const logOut = () => {
        localStorage.clear();
        navigate('/login');
    }
    return (
        <div className='w-full bg-[#FFF] flex justify-between items-center px-[40px] h-[80px]'>
            <img draggable={false} className='w-[167px] h-[24px]' src={Logo} alt='' />
            <div className='flex justify-between items-center'>
                {/* <div>Piyush</div> */}
                <div className='w-[50px] h-[50px] rounded-[2px] bg-[#FAFBFB] flex justify-center items-center cursor-pointer' data-tooltip-id="my-tooltip-1" onClick={logOut}>
                    <img draggable={false} className='cursor-pointer ' src={Logout} alt='' />
                </div>
            </div>

            <ReactTooltip
                id="my-tooltip-1"
                place="bottom"
                content="Logout"
            />
        </div>
    )
}