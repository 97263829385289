import React, { useRef, useState, useCallback } from 'react'
import { Header } from '../components/Header';
import Cards from '../components/Cards';

//first card
import ProfileUser from '../assets/images/Cards/ProfileUser.svg';
import Profile2user from '../assets/images/Cards/Profile2user.svg';

//second card
import Star from '../assets/images/Cards/star.svg';
import StarDisabled from '../assets/images/Cards/StarDisabled.svg';

//third card
import PaidUser from '../assets/images/Cards/PaidUser.svg';
import PaidUserGray from '../assets/images/Cards/PaidUserGray.svg';

//forth card
import EarningAmount from '../assets/images/Cards/EarningAmount.svg';
import EarningamountGray from '../assets/images/Cards/EarningamountGray.svg';

import SearchIcon from '../assets/images/Search.svg';
import GreenCircle from '../assets/images/greenCircle.svg';
import RedCircle from '../assets/images/redCircle.svg';
import More from '../assets/images/more.svg';
import CheckMark from '../assets/images/checkmark.svg';

import { useChangeUserStatus, useGetAllUsers, useGetTotalUser } from '../hooks/hooks';
import useOutsideClick from '../hooks/useOutsideClick';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import NodataFound from '../assets/images/people.svg';
import GrayPrevious from '../assets/images/GrayPrevious.svg';
import RedNext from '../assets/images/RedNext.svg';

import DisableNext from '../assets/images/DisableNext.svg';
import BlackPrevious from '../assets/images/BlackPrevious.svg';


import ArrowDown from '../assets/images/arrow-down.svg';
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";

const Home = () => {
    const navigate = useNavigate();

    const filterRef = useRef(null);
    const optionsRef = useRef(null);
    const searchRef = useRef(null);

    const [isOpen, setisOpen] = useState(false);
    const [SelectedIndex, setSelectedIndex] = useState(null);
    const [SelectedMenu, setSelectedMenu] = useState(false);
    const [SearchText, setSearchText] = useState("");

    const [IsOpenFilter, setIsOpenFilter] = useState(false);
    const [SelectedFilter, setSelectedFilter] = useState("All");
    const [SelectedFilterView, setSelectedFilterView] = useState("All");

    const [CurrentPage, setCurrentPage] = useState(1);

    const debouncedHandleSearch = useCallback(debounce(handleSearch, 1000), []);

    const { data: allUserData, isLoading: isAllUserLoading, error, refetch } = useGetAllUsers(SearchText, SelectedFilter, CurrentPage);
    const { data: totalUserData, isLoading: isTotalUserLoading, refetch : refetchTotalUsers } = useGetTotalUser();
    const { mutate: changeStatus, isLoading: isChangeStatus } = useChangeUserStatus();

    if (error && error?.response?.status === 401) {
        navigate('/login');
    }

    const onOpenMenu = (e, index) => {
        e.stopPropagation();
        setSelectedIndex(index);
        setisOpen(!isOpen);
    }

    const onSelectMenu = (e, isSelectedMenu, item) => {
        e.stopPropagation();
        setSelectedMenu(isSelectedMenu);
        setisOpen(!isOpen);
        let payload = { id: item.id, status: isSelectedMenu }
        changeStatus(payload, {
            onSuccess: (res) => {
                refetch();
                refetchTotalUsers();
            },
            onError: () => {
                console.log("Error");
            }
        });
    }

    const OnSelectFilter = (e, item) => {
        e.stopPropagation();
        // setSearchText("");
        // if (searchRef.current) {
        //     searchRef.current.value = "";
        // }
        setCurrentPage(1);
        setSelectedFilter(item);
        setSelectedFilterView(e.target.outerText);
        setIsOpenFilter(!IsOpenFilter);
        setTimeout(async () => {
            await refetch();
        }, 300);
    };

    function handleSearch(query) {
        setSearchText(query);
        setCurrentPage(1);
        //setSelectedFilter('All');
        //setSelectedFilterView('All');
        setTimeout(async () => {
            await refetch();
        }, 300);
    }

    const onNextPage = async () => {
        await setCurrentPage(CurrentPage + 1);
        await refetch();
    };

    const onPreviousPage = async () => {
        await setCurrentPage(CurrentPage - 1);
        await refetch();
    };

    useOutsideClick(filterRef, () => setIsOpenFilter(false));
    useOutsideClick(optionsRef, () => setisOpen(false));

    return (
        <div className='w-full bg-[#FAFBFB] select-none'>
            <Header />
            <div className='p-[40px] w-full flex flex-col'>
                <div className='grid grid-cols-3 max_sm:grid-cols-1  max_720:grid-cols-2 max_768:grid-cols-2 max_md:grid-cols-2 max_1024:grid-cols-2 gap-4'>
                    <Cards
                        cardIcon={ProfileUser}
                        CardTitle='Total Users'
                        CardIconUser={Profile2user}
                        isLoading={isTotalUserLoading}
                        totalCount={totalUserData?.totalUsers ? totalUserData?.totalUsers : 0}>
                    </Cards>
                    <Cards
                        cardIcon={Star}
                        CardTitle='Active Users'
                        CardIconUser={StarDisabled}
                        isLoading={isTotalUserLoading}
                        totalCount={totalUserData?.activeUsers ? totalUserData?.activeUsers : 0}>
                    </Cards>
                    <Cards
                        cardIcon={PaidUser}
                        CardTitle='Inactive Users'
                        CardIconUser={PaidUserGray}
                        isLoading={isTotalUserLoading}
                        totalCount={totalUserData?.deActiveUsers ? totalUserData?.deActiveUsers : 0}>
                    </Cards>
                    {/* <Cards
                        cardIcon={EarningAmount}
                        CardTitle='Earning Amount'
                        CardIconUser={EarningamountGray}
                        isLoading={isTotalUserLoading}
                        totalCount={totalUserData?.earningAmount ? '$' + totalUserData?.earningAmount : '$' + 0}>
                    </Cards> */}
                </div>
                <div className='flex justify-between mt-[30px] mb-[16px]'>
                    <div className='text-[20px] text-[#000] font-[600]'>Users Details</div>
                    <div className='flex items-center gap-3'>
                        <div className='w-[500px] h-[50px] bg-[#FFF] rounded-[2px] flex items-center'>
                            <img draggable={false} className='pl-[12px]' src={SearchIcon} alt='' />
                            <input className='text-input w-[98%] h-[20px] text-[#C2C3D1] mx-4 font-[400] text-[14px]'
                                name='Search'
                                type='text'
                                ref={searchRef}
                                disabled={isAllUserLoading}
                                placeholder='Search User'
                                onChange={(e) => { debouncedHandleSearch(e.target.value); }} />
                        </div>
                        {/* <div ref={filterRef} className={` ${isAllUserLoading ? 'pointer-events-none' : ''} flex relative items-center w-[130px] h-[50px] bg-[#FFF] rounded-[2px] justify-between p-[10px] cursor-pointer`}
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsOpenFilter(!IsOpenFilter);
                            }}>
                            <span className='text-[#141414] text-[16px] font-[400]'>{SelectedFilterView}</span>
                            <img draggable={false} className={`${IsOpenFilter ? 'rotate-180  transition-all' : ''}`} src={ArrowDown} alt='' />

                            <div className={`${IsOpenFilter ? 'block' : 'hidden'} w-[124px] leading-normal top-[55px] text-[14px] font-[400] absolute right-0 z-[999999] rounded-[10px] bg-[#FFF] p-[12px] flex text-[#141414] flex-col`}
                                style={{ 'boxShadow': '0px 4px 20px 0px rgba(0, 0, 0, 0.10)' }}>
                                <span onClick={(e) => { OnSelectFilter(e, "All"); }} className='pb-[12px] border-b border-[#E5E7F2] cursor-pointer flex justify-between'>All {SelectedFilterView == 'All' ? <><img src={CheckMark} /> </> : ''}</span>
                                <span onClick={(e) => { OnSelectFilter(e, "Free"); }} className='py-[12px] border-b border-[#E5E7F2] cursor-pointer flex justify-between' >Free plan {SelectedFilterView == 'Free plan' ? <><img src={CheckMark} /> </> : ''}</span>
                                <span onClick={(e) => { OnSelectFilter(e, "Purchase"); }} className='pt-[12px] cursor-pointer flex justify-between'>Paid plan {SelectedFilterView == 'Paid plan' ? <><img src={CheckMark} /> </> : ''}</span>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className='bg-[#FFF] rounded-[2px] w-full px-[16px]'>
                    <div className='w-full flex py-[18px] font-[500] border-b border-[#E5E7F2] text-[#141414]'>
                        <div className='w-[5%]'>No.</div>
                        <div className='w-[25%]'>Users name</div>
                        <div className='w-[50%]'>Email ID</div>
                        {/* <div className='w-[25%]'>Plan</div> */}
                        <div className='w-[20%]'>Status</div>
                    </div>

                    {!isAllUserLoading ? (
                        <>
                            {(allUserData?.data?.length === 0 || typeof (allUserData?.data) == "undefined") ? (
                                <>
                                    <div className='flex justify-center items-center flex-col mt-[50px]'>
                                        <img draggable={false} className='w-[80px] h-[80px]' src={NodataFound} alt=''></img>
                                        <div className='text-[20px] text-[#9495A9] font-[600] mt-[30px]'>No data available</div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {allUserData?.data?.map((item, index) => (
                                        <div
                                            key={index}
                                            className='relative w-full flex py-[16px] border-b font-[400] border-[#E5E7F2] text-[#9495A9]'>
                                            <div className='w-[5%]'>{CurrentPage === 1 ? (index + 1) : (index + 1) === 10 ? (10 * CurrentPage) : (CurrentPage - 1) + '' + (index + 1)}</div>
                                            <div className='w-[25%] capitalize break-words pr-[30px]'>{item?.name}</div>
                                            <div className='w-[50%] break-words pr-[30px]'>{item?.email}</div>
                                            {/* <div className='w-[25%] break-words capitalize'>{item?.plan ? item?.plan : 'Free'}</div> */}
                                            <div className='w-[20%] flex justify-between' ref={optionsRef}>
                                                <div className='flex items-center'>
                                                    <img draggable={false} src={item?.isActive ? GreenCircle : RedCircle} alt='' />
                                                    <span className='ml-[8px]'>{item?.isActive ? 'Active' : 'Disable'}</span>
                                                </div>
                                                <div className={`flex items-center ${isChangeStatus ? 'pointer-events-none' : ''}  cursor-pointer`} onClick={(e) => { onOpenMenu(e, index) }}>
                                                    <img draggable={false} src={More} alt='' />
                                                </div>
                                                <div className={`${index === SelectedIndex && isOpen ? 'block' : 'hidden'} w-[124px] leading-normal top-[40px] text-[14px] font-[400] absolute right-0 z-[999999] rounded-[10px] text-[#141414] bg-[#FFF] p-[12px] flex flex-col`}
                                                    style={{ boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.10)' }}>
                                                    <div onClick={(e) => { onSelectMenu(e, true, item); }} className={`${isChangeStatus || item.isActive ? 'pointer-events-none disabled' : 'cursor-pointer'} flex justify-between  pb-[12px] border-b`}>
                                                        <span>Active</span>
                                                        {item.isActive ? <><img src={CheckMark} /> </> : ''}
                                                    </div>
                                                    <div onClick={(e) => { onSelectMenu(e, false, item); }} className={`${isChangeStatus || !item.isActive ? 'pointer-events-none' : 'cursor-pointer'} flex justify-between pt-[12px] cursor-pointer`}>
                                                        <span >Disable</span>
                                                        {!item.isActive && <img src={CheckMark} />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <div className='flex justify-end items-center mt-[18px]'>
                                        <span className='text-[#9495A9] text-[14px] font-[400]'>Page</span>
                                        <img draggable={false} className={`${CurrentPage === 1 ? 'cursor-not-allowed pointer-events-none' : ''} cursor-pointer`} src={CurrentPage === 1 ? GrayPrevious : BlackPrevious} alt='' onClick={(e) => { e.stopPropagation(); onPreviousPage() }} />
                                        <span className='m-[7px] text-[14px] font-[400] text-[#9495A9]'>
                                            <span className='text-[#E23844] '>{CurrentPage}&nbsp;</span> of &nbsp;&nbsp; {allUserData.pageCount ? allUserData.pageCount : 0}</span>
                                        <img draggable={false} className={`${allUserData.pageCount === CurrentPage ? 'cursor-not-allowed pointer-events-none' : ''} ml-[10] cursor-pointer`}
                                            src={allUserData.pageCount === CurrentPage ? DisableNext : RedNext} alt='' onClick={(e) => { e.stopPropagation(); onNextPage() }} />
                                    </div>
                                </>)
                            }
                        </>
                    ) : (
                        <>
                            {Array.from({ length: 8 }, (_, index) => (
                                <div className="flex gap-[16px] px-[8px] items-center my-[6px] overflow-hidden" key={index}>
                                    <Skeleton height={40} width={2000} />
                                </div>
                            ))}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
export default Home