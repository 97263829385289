import React from 'react'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Cards = ({
    cardIcon,
    CardIconUser,
    CardTitle,
    totalCount,
    isLoading
}) => {
    return (
        <div className='h-[193px] rounded-[10px] bg-[#FFF] p-[30px] !pr-[0px] flex justify-between'
            style={{ boxShadow: '4px 8px 20px 0px rgba(20, 20, 20, 0.05)' }}>
            <div className='w-fit'>
                <img draggable={false} alt='' className='pb-[16px]' src={cardIcon} />
                <div className='text-[20px] text-[#9495A9] font-[400] pb-[7px]'>{CardTitle}</div>
                {isLoading ?
                    <>
                        <div className='w-full overflow-hidden'>
                            <Skeleton height={40} width={60} />
                        </div>
                    </>
                    :
                    <>
                        <div className='text-[40px] text-[#221b1b] font-[600]'>{totalCount}</div>
                    </>
                }
            </div>
            <div className='w-fit flex justify-center items-center'>
                <img draggable={false} alt='' src={CardIconUser} />
            </div>
        </div>
    )
}

export default Cards