import { signIn } from "../API/auth";
import { useMutation, useQuery } from 'react-query';
import { getAllUsers, getTotalUser, changeUserStatus } from "../API/home";

export const useSignIn = () => {
    return useMutation({
        mutationFn: (data) => signIn(data)
    });
};

export const useGetAllUsers = (searchText, SelectedFilter, CurrentPage) => {
    return useQuery({
        queryKey: ['All USER'],
        queryFn: () => getAllUsers(searchText, SelectedFilter, CurrentPage),
        onError: (error) => { return error },
        select: (response) => {
            const { data, pageCount } = response.data;
            return { data, pageCount };
        }
    });
};

export const useGetTotalUser = () => {
    return useQuery({
        queryKey: ['All Total USER'],
        queryFn: () => getTotalUser(),
        select: (response) => {
            const { data } = response;
            return data;
        }
    });
};

export const useChangeUserStatus = () => {
    return useMutation({
        mutationFn: (payload) => changeUserStatus(payload)
    });
};