import React, { useState } from 'react'
import EyeIcon from './../assets/images/eye.svg'
import closeEyeIcon from './../assets/images/EyeClose.svg'

const InputFields = ({
    name,
    label,
    type,
    className,
    placeholder,
    value,
    handleChange,
    isFixedLabel,
    isRegistered,
    disabled=false
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    return (
        <>
            <div className='input-container relative w-full'>
                <input
                    className={`appearance-none ${className}`}
                    name={name}
                    type={
                        (name === 'password' || name === 're_password') && showPassword
                            ? 'text'
                            : type === 'email'
                                ? 'text'
                                : type
                    }
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    value={value}
                    onChange={handleChange}
                    placeholder={placeholder}
                    disabled={disabled}
                />

                <label
                    className={`input-label bg-white inline-flex absolute text-[14px] font-[300] leading-6 text-[#C2C3D1] px-[2px] 
                    ${((type === 'email' || type === 'password') && (value || isFocused)) || (type === 'text' && isFixedLabel)
                            ? 'filled text-[12px]'
                            : ''
                        } 
                    ${type === 'date' && 'right-[15px]'}
                    ${type === 'number' && 'hidden'}
                    ${type === 'email' && isRegistered && 'hidden'}
            `}>
                    {label}
                </label>
                {type === 'password' && (
                    <div
                        className="absolute top-[50%] -translate-y-[50%] right-[15px] cursor-pointer"
                        onClick={() => handleShowPassword()}
                    >
                        {!showPassword ? <img draggable={false} src={EyeIcon} alt="eyeicon" /> : <img draggable={false} src={closeEyeIcon} alt="closeEyeIcon" />}
                    </div>
                )}
            </div>
        </>
    )
}

export default InputFields;