import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Home from './pages/Home';
import { QueryClient, QueryClientProvider } from 'react-query';
import ProtectedRoutes from './hooks/ProtectedRoutes';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { SkeletonTheme } from 'react-loading-skeleton';

const queryClient = new QueryClient(
  {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
}
);
// const token = localStorage.getItem('AccessToken');

function App() {
  return (
    <SkeletonTheme baseColor="#F3F4F8" highlightColor="#F9F9FB" borderRadius="2px">
      <QueryClientProvider client={queryClient}>
        <ToastContainer />
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={
              <ProtectedRoutes redirectTo="/login">
                <Home />
              </ProtectedRoutes>} />
            <Route path="*" element={
              <ProtectedRoutes redirectTo="/login">
                <Home />
              </ProtectedRoutes>} />
          </Routes>
        </Router>
      </QueryClientProvider>
    </SkeletonTheme>
  );
}
export default App;